import React from "react"
import Button from "../components/Button"
import Layout from "../components/layout/Layout"
import styles from "../styles/e404.module.scss"
import SEO from "../components/SEO"

export default () => {
  return (
    <Layout>
      <SEO title="Umělecké kovářství" />
      <main className={styles.errorContainer}>
        <div className={styles.errorText}>
          <h1 className={styles.heading}>Stránka nenalezena</h1>
          <Button link="/" text="Domovská stránka" type="main" />
        </div>
      </main>
    </Layout>
  )
}
